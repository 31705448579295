<template>
  <div class="bg-gray-100 h-screen op">
    <div class="flex justify-center items-center mb-10">
      <img class="w-24" :src="images.logo" alt="logo" original />
      <span class="ml-4 font-extrabold text-4xl text-identityColor">Flash</span>
    </div>
    <div class="w-11/12 md:w-4/5 lg:w-2/5 mx-auto my-3">
      <div class="p-10 bg-white shadow-lg rounded-lg">
        <div class="font-mono font-semibold text-3xl text-center mb-5">
          Bienvenue
        </div>
        <div>
          <div v-if="error" class="text-red-600">{{ error }}</div>
          <form action="#" @submit.prevent="Login">
            <div class="mb-4">
              <div for="email" class="label font-normal mb-2 text-gray-500">
                Adresse email
              </div>

              <div>
                <input
                  id="email"
                  type="email"
                  class="w-full border rounded-md h-14 border-gray-400 p-4"
                  name="email"
                  value
                  required
                  autofocus
                  v-model="email"
                />
              </div>
            </div>

            <div class="label font-normal mb-6 text-gray-500">
              <div for="password" class="label font-normal mb-2 text-gray-500">
                Mot de passe
              </div>

              <div>
                <input
                  id="password"
                  type="password"
                  class="w-full border rounded-md h-14 border-gray-400 p-4"
                  name="password"
                  required
                  v-model="password"
                />
              </div>
            </div>

            <div @click="loading">
              <div class="flex justify-center mb-2">
                <easy-spinner v-if="charge" type="dots" size="30" />
              </div>
              <button
                type="submit"
                class="w-full bg-identityColor hover:bg-yellow-600 hover:text-gray-200 py-4 md:px-14 px-5 text-white font-semibold rounded-md"
              >
                Connexion
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="footer text-center font-semibold mt-10">
      {{ $t("flash") + "2022 - " + new Date().getFullYear() }}
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
import logo from "@/assets/images/img_logo.png";
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "LoginComponent",
  data() {
    return {
      icons: { close },
      images: {
        logo,
      },
      charge: false,
    };
  },
  methods: {
    loading() {
      this.charge = true;
    },
  },
  setup() {
    const email = ref("");
    const password = ref("");
    const error = ref(null);
    const store = useStore();
    const router = useRouter();
    const Login = async () => {
      try {
        await store.dispatch("logIn", {
          email: email.value,
          password: password.value,
        });
        router.push("/yBdashboard");
      } catch (err) {
        this.charge = false;
        error.value = err.message;
      }
    };
    return { Login, email, password, error };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";

.op {
  padding-top: 6%;
}
.ip {
  color: #7f8896;
}

.content {
  background: #f3f5fa;
}
.principal_bloc {
  box-shadow: 0px 9px 12px rgba(9, 30, 66, 0.15),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  height: 400px;
}
.ipt {
  border: 1px solid #ededee;
}
.forget_password {
  color: #b5bac3;
}
.form-button:deep(.button-base) {
  .page-button-real {
    width: 100%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.18);
  }
}
</style>
