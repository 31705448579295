<template>
  <div class="Login">
    <Login />
  </div>
</template>

<script>
/*Import Components */
import Login from "@/components/container/Login/index.vue";

export default {
  name: "index",
  metaInfo: {
    title: "bitcoinflash",
    meta: [
      {
        name: "bitcoinflash",
        content:
          "Acheter du credit, du forfait internet avec des satoshi en utilisant le lightning network",
      },
    ],
  },
  components: { Login },
  data() {
    return {
      icons: {},
    };
  },
  methods: {
    openHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/style/sass/variables.scss";

.img-fill {
  fill: transparent;
}
</style>
